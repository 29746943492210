<template>
  <div v-if="shouldDislay" class="flex justify-center">
    <span class="text-static-default-low body-2">
      {{ i18n(translations.itemsNotReserved) }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './ItemsNotReserved.translations'

const experiments = useExperiments()
const i18n = useI18n()

const shouldDislay = computed(
  () => experiments['experiment.cartLockedBlock'] === 'cartLockedBlockEnabled',
)
</script>
