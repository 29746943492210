import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  itemsNotReserved: {
    id: 'checkout_items_not_reserved',
    defaultMessage: 'Items in the cart aren’t reserved.',
  },
}

export default translations
