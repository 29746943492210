export default {
  automatedMessage: {
    id: 'buyback_conversation_automated_message',
    defaultMessage: 'Automated message',
  },
  automatedDeletionMessage: {
    id: 'buyback_conversation_automated_deletion_message',
    defaultMessage: 'Messages are automatically deleted after one year.',
  },
  loadMore: {
    id: 'buyback_conversation_load_more_button',
    defaultMessage: 'Load more',
  },
}
