import { useRoute } from '#imports'

import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

// Define a function called diff_minutes that calculates the difference in minutes between two Date objects (dt2 and dt1)
function diffMinutes(dt2: Date, dt1: Date) {
  // Calculate the difference in milliseconds between the two provided dates and convert it to seconds
  let diff = (dt2.getTime() - dt1.getTime()) / 1000
  // Convert the difference from seconds to minutes
  diff /= 60

  // Return the absolute value of the rounded difference in minutes
  return Math.abs(Math.round(diff))
}

export default () => {
  const { openErrorToast } = useTheToast()

  const logger = useLogger()
  const route = useRoute()
  const pageOpenedAt = new Date()

  // Rename to generic error handler
  const handleUnauthorizedUser = async (
    error: Record<string, unknown>,
    errorLogMessage?: string,
  ) => {
    const userStore = useUserStore()
    const pageFailedAt = new Date()

    switch (error?.status) {
      case 401:
        openErrorToast()
        logger.error(`[CHECKOUT] 401 from ${route.name as string}`, {
          ...error,
          pageOpenedAt,
          pageFailedAt,
          pageTimeDiff: diffMinutes(pageFailedAt, pageOpenedAt),
          owners: ['bot-squad-checkout-front'],
        })

        return { isHandled: false }
      case 403:
        openErrorToast()

        // In case of failure we refetch the user to refresh the token
        await userStore.fetchUser()

        logger.error(`[CHECKOUT] 403 from ${route.name as string}`, {
          ...error,
          pageOpenedAt,
          pageFailedAt,
          pageTimeDiff: diffMinutes(pageFailedAt, pageOpenedAt),
          owners: ['bot-squad-checkout-front'],
        })

        return { isHandled: false }
      default:
        // Display generic error toast to give some feedback to the user
        openErrorToast()
        logger.error(
          errorLogMessage ||
            `[CHECKOUT] Unhandled promise error in ${route.name as string}`,
          {
            errors: { ...error },
            owners: ['bot-squad-checkout-front'],
          },
        )

        return { isHandled: false }
    }
  }

  return { handleUnauthorizedUser }
}
