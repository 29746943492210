import { computed, ref, watchEffect } from 'vue'

import type {
  BuybackMessage,
  BuybackOrderMessage,
} from '@backmarket/http-api/src/api-specs-buyback/models'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import {
  type Role,
  getConversationsEndoints,
} from '@backmarket/nuxt-layer-buyback/components/OrderConversation/OrderConversation.constants'

import { conversationAdapter, detailsAdapter } from './adapters'

export function useConversation(role: Role, id: string) {
  const i18n = useI18n()
  const logger = useLogger()
  const locale = useI18nLocale()
  const { openErrorToast } = useTheToast()

  const pageInput = ref('1')
  const messageInput = ref<BuybackMessage>()
  const storedMessages = ref<BuybackOrderMessage[]>([])

  const { getConversation, getMetadata, postMessage } =
    getConversationsEndoints(role)

  const userMessage = computed(() => {
    if (messageInput.value) {
      const body = new FormData()

      if (messageInput.value.message) {
        body.append('message', messageInput.value.message)
      }
      if (messageInput.value?.files && messageInput.value.files.length > 0) {
        messageInput.value.files.forEach((file) => {
          body.append('files', file)
        })
      }

      return body
    }

    return {}
  })

  const {
    data: postMessageResponse,
    error: postMessageError,
    status: postMessageStatus,
  } = useHttpFetch(postMessage, {
    body: userMessage,
    pathParams: { id },
    immediate: false,
  })

  const {
    data: conversationResponse,
    error: conversationError,
    pending: isLoadingConversation,
  } = useHttpFetch(getConversation, {
    pathParams: { id },
    queryParams: { page: pageInput, pageSize: 20 },
  })

  const {
    data: metadataResponse,
    error: metadataError,
    pending: isLoadingMetadata,
  } = useHttpFetch(getMetadata, {
    pathParams: { id },
  })

  if (conversationError.value) {
    logger.error('[BUYBACK][API][CONVERSATION] Error getting conversation', {
      error: conversationError.value,
      owners: ['bot-squad-circularity-order-lifecycle-front'],
    })
  }

  if (metadataError.value) {
    logger.error(
      '[BUYBACK][API][CONVERSATION] Error getting conversation metadata',
      {
        error: metadataError.value,
        owners: ['bot-squad-circularity-order-lifecycle-front'],
      },
    )
  }

  if (postMessageError.value) {
    logger.error(
      '[BUYBACK][API][CONVERSATION] Error posting conversation message',
      {
        error: postMessageError.value,
        owners: ['bot-squad-circularity-order-lifecycle-front'],
      },
    )
  }

  const conversation = computed(() => {
    if (
      storedMessages.value.length === 0 &&
      conversationResponse.value?.results
    ) {
      return conversationAdapter(
        conversationResponse.value,
        conversationResponse.value.results,
      )
    }
    if (storedMessages.value.length > 0 && conversationResponse.value) {
      return conversationAdapter(
        conversationResponse.value,
        storedMessages.value,
      )
    }

    return undefined
  })

  const metadata = computed(() => {
    if (metadataResponse.value) {
      return detailsAdapter(metadataResponse.value, role, id, i18n, locale)
    }

    return undefined
  })

  const isLoadingPostMessage = computed(() => {
    if (postMessageStatus.value === 'pending') {
      return true
    }

    return false
  })

  const isLoadingMore = computed(() => !!conversation.value?.next)

  watchEffect(() => {
    if (conversationResponse.value?.results) {
      storedMessages.value.push(...conversationResponse.value.results)
    }
  })

  watchEffect(() => {
    if (
      conversationError.value ||
      metadataError.value ||
      postMessageError.value
    ) {
      openErrorToast()
    }
  })

  watchEffect(() => {
    if (postMessageResponse.value) {
      storedMessages.value.unshift(postMessageResponse.value)
    }
  })

  const loadMore = () => {
    if (conversation.value?.next) {
      pageInput.value = conversation.value.next
    }
  }

  return {
    conversation,
    metadata,
    isLoadingConversation,
    isLoadingMetadata,
    isLoadingPostMessage,
    isLoadingMore,
    messageInput,
    pageInput,
    loadMore,
  }
}
